import { SelectionChangedEvent } from "ag-grid-community";
import { TariffCoaMappingEntity } from "domain/entity/TariffCoaMapping/TariffCoaMappingEntity";
import { INITIAL_TARIFF_COA_MAPPING_COL_DEF } from "presentation/constant/TariffCoaMapping/TariffCoaMappingColumnDefinition";
import { TariffCoaMappingConstant } from "presentation/constant/TariffCoaMapping/TariffCoaMappingConstant";
import { useTariffCoaMappingVM } from "presentation/hook/TariffCoaMapping/useTariffCoaMappingVM";
import { useTariffCoaMappingTracked } from "presentation/store/TariffCoaMapping/TariffCoaMappingProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
const TariffCoaMappingTablePanel = () => {
    const gridRef: any = useRef();
    const [tariffCoaMappingState] = useTariffCoaMappingTracked();
    const tariffCoaMappingVM = useTariffCoaMappingVM();
    const TARIFF_COA_MAPPING_CONSTANT = TariffCoaMappingConstant.Title;

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        tariffCoaMappingVM.updateSelectedRows(selectedRows);
    }, [tariffCoaMappingVM])

    const handleRowDoubleClick = useCallback((entity: TariffCoaMappingEntity) => {
        tariffCoaMappingVM.onRowDoubleClick(entity);
    }, [tariffCoaMappingVM])

    const handleAdd = useCallback(() => {
        tariffCoaMappingVM.onAddClick();
    }, [tariffCoaMappingVM]);

    const memoTariffCoaMappingTable = useMemo(() => {

        return (
            <NbisTable
                id='tariff-coa-mapping-table'
                isNewColumnSetting={true}
                headerLabel={TARIFF_COA_MAPPING_CONSTANT.TITLE}
                columns={INITIAL_TARIFF_COA_MAPPING_COL_DEF}
                data={tariffCoaMappingState.tariffCoaMappingList ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAdd}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: TariffCoaMappingEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [TARIFF_COA_MAPPING_CONSTANT.TITLE, tariffCoaMappingState.tariffCoaMappingList, handleAdd, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
        {memoTariffCoaMappingTable}
    </TableWrapper>
    </>;
}

export default memo(TariffCoaMappingTablePanel);
